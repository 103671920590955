import Cookies from "universal-cookie";

export default function cookiebar() {
  const year = 365;
  const day = 24;
  const minutes = 60;
  const seconds = 1000;

  const cookieBar = document.querySelector(".cookiebar");
  const cookieBarHint = document.querySelector(".cookiebar__hint");
  const cookieBarButton = document.querySelector(".cookiebar__button");
  const cookieBarResetButton = document.querySelector(
    ".cookiebar-reset__button"
  );
  const cookies = new Cookies();

  let isCookiebarActive = cookies.get("pswCookieNotice") ? false : true;

  if (isCookiebarActive && cookieBar) {
    cookieBar.classList.add("cookiebar--visible");
  }

  if (!isCookiebarActive) {
    const _paq = window._paq || [];
    _paq.push(["trackPageView"]);
    _paq.push(["enableLinkTracking"]);
  }

  if (cookieBarHint) {
    cookieBarHint.addEventListener("click", () => {
      cookieBarButton.disabled = cookieBarButton.disabled ? false : true;
    });
  }

  if (cookieBarButton) {
    cookieBarButton.addEventListener("click", () => {
      const date = new Date();

      date.setTime(date.getTime() + year * day * minutes * minutes * seconds);

      cookies.set("pswCookieNotice", "1", {
        path: "/",
        expires: date
      });

      cookieBar.classList.remove("cookiebar--visible");
      isCookiebarActive = !isCookiebarActive;
      const _paq = window._paq || [];
      _paq.push(["trackPageView"]);
      _paq.push(["enableLinkTracking"]);
    });
  }

  if (cookieBarResetButton) {
    cookieBarResetButton.addEventListener("click", e => {
      cookieBar.classList.add("cookiebar--visible");
      cookies.remove("pswCookieNotice");
      e.preventDefault();
    });
  }
}
