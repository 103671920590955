export default function navList() {
  toggleMobileNavigationElements();
  showAndHideDropdownMenuItemsOnHover();
}

function toggleMobileNavigationElements() {
  const MOBILE_VIEWPORT = 910;

  let currentWidth = window.innerWidth;

  _toggleMobileNavigationElements();

  window.addEventListener("resize", (e) => {
    e.stopPropagation();

    if (currentWidth === window.innerWidth) {
      return;
    }

    currentWidth = window.innerWidth;
    _toggleMobileNavigationElements();
  });

  function _toggleMobileNavigationElements() {
    if (window.innerWidth <= MOBILE_VIEWPORT) {
      const dropdownElements = Array.from(
        document.querySelectorAll(".nav-list__navbar-dropdown-label")
      );

      dropdownElements.forEach(dropdownElement => {
        dropdownElement.addEventListener("click", event => {
          event.preventDefault();
          dropdownElement.focus();

          if (
            dropdownElement.classList.contains(
              "nav-list__navbar-dropdown-label--active"
            )
          ) {
            dropdownElement.classList.remove(
              "nav-list__navbar-dropdown-label--active"
            );
            document.activeElement.blur();
          } else {
            dropdownElement.classList.add("nav-list__navbar-dropdown-label--active");
            dropdownElement.focus();
          }
        });
      });
    }
  }
}


function showAndHideDropdownMenuItemsOnHover() {
  const MOBILE_VIEWPORT = 910;

  _showAndHideDropdownMenuItemsOnHover();

  window.addEventListener("resize", () => {
    _showAndHideDropdownMenuItemsOnHover();
  });

  function _showAndHideDropdownMenuItemsOnHover() {
    const dropdownElements = Array.from(
      document.querySelectorAll(".nav-list__navbar-item")
    );

    dropdownElements.forEach(dropdownElement => {
      dropdownElement.addEventListener("mouseenter", () => {
        if (window.innerWidth > MOBILE_VIEWPORT) {
          dropdownElement.focus();
        }
      });

      dropdownElement.addEventListener("click", () => {
        if (window.innerWidth > MOBILE_VIEWPORT) {
          dropdownElement.focus();
        }
      });

      dropdownElement.addEventListener("mouseleave", () => {
        if (window.innerWidth > MOBILE_VIEWPORT) {
          document.activeElement.blur();
        }
      });
    });
  }
}