const SLOW_NUMBER_INCREMENT_SPEED = 100;
const FAST_NUMBER_INCREMENT_SPEED = 5;

export default function facts() {
  document.querySelectorAll(".facts").forEach(element => {
    const observer = new IntersectionObserver(entries =>
      handler(element, entries)
    );
    observer.observe(element);
  });
}

function handler(element, entries) {
  entries.forEach(entry => {
    if (entry.isIntersecting) {
      const numbers = element.querySelectorAll(".facts__number");

      numbers.forEach(number => {
        let baseCount = 0;
        const speed =
          number.dataset.speed === "fast"
            ? FAST_NUMBER_INCREMENT_SPEED
            : SLOW_NUMBER_INCREMENT_SPEED;
        const total = parseInt(number.dataset.total, 10);

        setInterval(() => {
          if (baseCount !== total) {
            baseCount++;
            number.innerHTML = baseCount;
          }
        }, speed);
      });
    }
  });
}
