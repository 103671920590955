import flickity from "flickity";

export default function servicesSlideshow() {
  const servicesSlideshow = document.querySelectorAll(
    ".services-slideshow__inner"
  );

  [].forEach.call(servicesSlideshow, slideshowElement => {
    /* eslint-disable no-unused-vars */
    const flkty = new flickity(slideshowElement, {
      imagesLoaded: true,
      cellAlign: "left",
      contain: true,
      wrapAround: true,
      groupCells: "50%",
      pageDots: false
    });
  });
}
