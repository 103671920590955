// fade-in
import fadeIn from "./fade-in.js";

// blocks
import facts from "../../site/plugins/custom-components/src/blocks/facts/facts.js";
import faqList from "../../site/plugins/custom-components/src/blocks/faq-list/faq-list.js";
import imageStack from "../../site/plugins/custom-components/src/blocks/image-stack/image-stack.js";
import infoBoxes from "../../site/plugins/custom-components/src/blocks/info-boxes/info-boxes.js";
import jobFilter from "../../site/plugins/custom-components/src/blocks/job-filter/job-filter.js";
import magazineHeader from "../../site/plugins/custom-components/src/snippets/magazine-header/magazine-header.js";
import servicesSlideshow from "../../site/plugins/custom-components/src/blocks/services-slideshow/assets/services-slideshow.js";

// snippets
import cookiebar from "../../site/plugins/custom-components/src/snippets/cookiebar/cookiebar.js";
import magazineOverlay from "../../site/plugins/custom-components/src/snippets/magazine-overlay/magazine-overlay.js";
import nav from "../../site/plugins/custom-components/src/snippets/nav/nav.js";
import navList from "../../site/plugins/custom-components/src/snippets/nav-list/nav-list.js";
import rail from "../../site/plugins/custom-components/src/snippets/rail/rail.js";
import slideshow from "../../site/plugins/custom-components/src/snippets/slideshow/slideshow.js";
import videoModal from "../../site/plugins/custom-components/src/snippets/video-modal/video-modal.js";

document.addEventListener("DOMContentLoaded", () => {
  // For CSP headers we can't use inline styles, so have to do this work around
  // https://github.com/bnomei/kirby3-security-headers/issues/4
  // NOTE: json string needs " so wrap in '.
  // <div id="special" data-style='{ "backgroundColor": "#8EE", "fontSize": 28 }'>...</div>
  const elements = document.querySelectorAll("[data-style]");

  // Loop through the selected elements
  elements.forEach((ele) => {
    const data = ele.getAttribute("data-style");

    if (data !== null && data.length > 0) {
      const style = JSON.parse(data);

      // Apply the inline styles to the element
      for (const prop in style) {
        if (Object.hasOwnProperty.call(style, prop)) {
          ele.style[prop] = style[prop];
        }
      }
    }
  });
  cookiebar();
  facts();
  fadeIn();
  faqList();
  imageStack();
  infoBoxes();
  jobFilter();
  magazineHeader();
  magazineOverlay();
  nav();
  navList();
  rail();
  servicesSlideshow();
  slideshow();
  videoModal();
});
