export default function faqList() {
  const dropdowns = document.querySelectorAll(
    ".faq-list__custom-select-wrapper"
  );
  const faqs = document.querySelectorAll(".faq-list__faq");

  dropdowns.forEach(dropdown => {
    dropdown.addEventListener("click", () => {
      dropdown
        .querySelector(".faq-list__custom-select")
        .classList.toggle("faq-list__custom-select--open");
      dropdown.querySelectorAll(".faq-list__custom-option").forEach(option => {
        option.addEventListener("click", () => {
          const category = document.querySelector(
            `[data-category="${option.dataset.value}"]`
          );
          const activeCategory = document.querySelector(
            ".faq-list__category--active"
          );

          activeCategory.classList.remove("faq-list__category--active");
          category.classList.add("faq-list__category--active");

          if (!option.classList.contains("faq-list__custom-option--selected")) {
            option.parentNode
              .querySelector(".faq-list__custom-option--selected")
              .classList.remove("faq-list__custom-option--selected");
            option.classList.add("faq-list__custom-option--selected");
            option
              .closest(".faq-list__custom-select")
              .querySelector(
                ".faq-list__custom-select__trigger span"
              ).textContent = option.textContent;
          }
        });
      });
    });
  });

  window.addEventListener("click", event => {
    document.querySelectorAll(".faq-list__custom-select").forEach(select => {
      if (!select.contains(event.target)) {
        select.classList.remove("faq-list__custom-select--open");
      }
    });
  });

  faqs.forEach(list => {
    list.addEventListener("click", event => {
      const element = event.target;
      toggleActiveItem(element);
      toggleActiveItem(element.parentElement);
    });
  });
}

function toggleActiveItem(element) {
  if (element.classList.contains("faq-list__faq")) {
    element.classList.toggle("faq-list__faq--active");
  }
}
