import Headroom from "headroom.js";

export default function nav() {
  const header = document.querySelector(".nav, .styleguide__nav");

  if (!header) {
    return;
  }

  const headroom = new Headroom(header, {
    offset: 100
  });

  headroom.init();

  const menuButton = document.querySelector(".nav__hamburger");
  const menu = document.getElementById("menu");
  const navElement = document.querySelector(".nav");

  let isMenuOpen = false;
  let isHidden = true;

  if (menuButton) {
    menuButton.addEventListener("click", () => {
      document.body.classList.toggle("default--has-overlay");
      menuButton.classList.toggle("nav__hamburger--open");
      menu.classList.toggle("nav__list--open");
      navElement.classList.toggle("nav--open");
      isMenuOpen = !isMenuOpen;
      isHidden = !isHidden;
      menuButton.setAttribute("aria-expanded", isMenuOpen);
      menu.setAttribute("aria-hidden", isHidden);
    });
  }

  window.addEventListener("resize", () => {
    const MOBILE_VIEWPORT = 910;

    if (window.innerWidth >= MOBILE_VIEWPORT) {
      menuButton.classList.remove("nav__hamburger--open");
      menu.classList.remove("nav__list--open");
      navElement.classList.remove("nav--open");
      isMenuOpen = false;
      isHidden = false;
      menuButton.setAttribute("aria-expanded", isMenuOpen);
      menu.setAttribute("aria-hidden", isHidden);
    }
  });
}
