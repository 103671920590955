import flickity from "flickity-fade";

export default function slideshow() {
  const mainSlideshow = document.querySelectorAll(".js-slideshow");

  [].forEach.call(mainSlideshow, slideshowElement => {
    /* eslint-disable no-unused-vars */
    const flkty = new flickity(slideshowElement, {
      imagesLoaded: true,
      autoPlay: 5000,
      fade: true,
      pageDots: false,
      adaptiveHeight: true
    });
  });
}
