export default function magazineOverlay() {
  const menuButton = document.querySelector(".magazine-overlay__button");
  const editionLink = document.querySelector(".magazine-nav__link--edition");
  const backLink = document.querySelector(".magazine-overlay__back-button");

  if (!menuButton || !editionLink || !backLink) {
    return;
  }

  let menuIsActive = false;

  [menuButton, editionLink, backLink].forEach(element => {
    element.addEventListener("click", event => {
      event.preventDefault();
      menuButton.classList.toggle("magazine-overlay__button--hidden");
      toggleMenu();
    });
  });

  function toggleMenu() {
    if (menuIsActive) {
      return hideMenu();
    }

    return showMenu();
  }

  function hideMenu() {
    menuIsActive = false;
    document.activeElement.blur();
  }

  function showMenu() {
    menuIsActive = true;
    menuButton.focus();
  }
}
