export default function fadeIn() {
  document.querySelectorAll(".fade-in").forEach((element) => {
    const observer = new IntersectionObserver((entries) =>
      handler(element, entries)
    );
    observer.observe(element);
  });
}

function handler(element, entries) {
  const DELAY = 250;

  entries.forEach((entry) => {
    if (entry.isIntersecting) {
      setTimeout(() => {
        element.classList.add("fade-in--in-view");
      }, DELAY);
    }
  });
}
