export default function magazineHeader() {
  const element = document.querySelector(".magazine-header__share");
  const button = document.querySelector(".magazine-header__share-title");

  if (!element || !button) {
    return;
  }

  document.addEventListener("click", event => {
    if (event.target !== button) {
      element.classList.remove("magazine-header__share--active");
    }
  });

  button.addEventListener("click", () => {
    element.classList.add("magazine-header__share--active");
  });
}
