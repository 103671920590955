export default function videoModal() {
  const playButtons = document.querySelectorAll(".play-button");
  const autoPlay = "?autoplay=1";

  [].forEach.call(playButtons, playButton => {
    const modal = document.querySelector(
      `.video-modal[data-id="${playButton.dataset.id}"]`
    );
    const embedUrl = playButton.dataset.video;

    if (!modal | !embedUrl) {
      return;
    }

    const iframe = modal.querySelector("iframe");
    const closeButton = modal.querySelector(".video-modal__close");

    if (!iframe | !closeButton) {
      return;
    }

    playButton.addEventListener("click", () => {
      modal.classList.add("video-modal--visible");
      modal.setAttribute("aria-hidden", "false");
      iframe.src = embedUrl + autoPlay;
    });

    if (modal && closeButton) {
      closeButton.addEventListener("click", () => {
        modal.classList.remove("video-modal--visible");
        modal.setAttribute("aria-hidden", "true");
        iframe.src = "";
      });
    }

    if (modal) {
      modal.addEventListener("click", e => {
        if (e.target === modal) {
          modal.classList.remove("video-modal--visible");
          modal.setAttribute("aria-hidden", "true");
          iframe.src = "";
        }
      });
    }
  });
}
