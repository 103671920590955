import { Elm } from "./JobFilter.elm";

export default function jobFilter() {
  const jobFilters = document.querySelectorAll(".job-filter");
  jobFilters.forEach(jobFilter => {
    Elm.JobFilter.init({
      node: jobFilter,
      flags: JSON.parse(jobFilter.dataset.flags)
    });
  });
}