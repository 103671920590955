import flickity from "flickity";
require("flickity-imagesloaded");

export default function rail() {
  document.querySelectorAll(".rail__rail").forEach(element => {
    const railId = element.dataset.railId;

    if (!railId) {
      return;
    }

    window[element.dataset.railId] = new flickity(element, {
      prevNextButtons: true,
      cellAlign: "left",
      pageDots: false,
      contain: true,
      groupCells: 1,
      lazyLoad: true,
      imagesLoaded: true
    });
  });
}
