import flickity from "flickity";

export default function infoBoxes() {

  const infoBoxesSlideshow = document.querySelectorAll(
    ".info-boxes__slideshow"
  );

  [].forEach.call(infoBoxesSlideshow, slideshowElement => {
    /* eslint-disable no-unused-vars */
    const flkty = new flickity(slideshowElement, {
      imagesLoaded: true,
      pageDots: false
    });
  });
}
